/* Global font and height settings */
* {
  font-family: 'Montserrat', Helvetica, Arial, Lucida, sans-serif;
}

html, body, #root {
  height: 100%;
}

/* Custom Scrollbar Styles */
*::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

*::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

*::-webkit-scrollbar-thumb {
  background-color: #cccccc;
  border-radius: 10px;
  border: 3px solid #f1f1f1;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #aaaaaa;
}

/* For Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #cccccc #f1f1f1;
}
